import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BulkImportPage from 'modules/bulk-imports/components/BulkImportPage';
import CompanyDuplicateMergeRecordSelection from 'modules/duplicate-merge/components/CompanyDuplicateMergeRecordSelection';
import CompanyDuplicateMergeAttributes from 'modules/duplicate-merge/components/CompanyDuplicateMergeAttributes';
import ContactDuplicateMergeRecordSelection from 'modules/duplicate-merge/components/ContactDuplicateMergeRecordSelection';
import ContactDuplicateMergeAttributes from 'modules/duplicate-merge/components/ContactDuplicateMergeAttributes';
import ContactRecordIndexPage from 'modules/recordIndex/ContactRecordIndexPage';
import CompaniesPage from 'modules/company-pages/CompaniesPage';
import CompanyRecordIndexPage from 'modules/recordIndex/CompanyRecordIndexPage';
import PendingContactsPage from 'modules/pending-contacts/PendingContactsPage';
import PipelineIndexPage from 'modules/pipelines/PipelineIndexPage';
import SearchRecordIndexPage from 'modules/recordIndex/SearchRecordIndexPage';
import SearchRequestIndexPage from 'modules/search-requests/components/SearchRequestIndexPage';
import SearchRequestPage from 'modules/search-requests/components/SearchRequestPage';
import SearchRequestProfilePage from 'modules/search-requests/components/SearchRequestProfilePage';
import SearchesPage from 'modules/search-views/searchPage/SearchesPage';
import TalentPoolsDashboard from 'modules/talent-pools/components/Dashboard';
import TasksPage from 'modules/tasks/components/Dashboard';
import ContactPage from '../../components/contact/ContactPage';
import CandidatePortalConsentPageEnhanced from './pages/contact/CandidatePortalConsentPageEnhanced';
import EmailTemplatesPage from './pages/EmailTemplatesPage';
import NotificationsPage from './pages/NotificationsPage';
import ReportsPage from './pages/ReportsPage';
import ContactForm from '../../components/contact/ContactForm';

const AppPages = () => {
  return (
    <Switch>
      <Route component={BulkImportPage} exact={true} path='/bulk_import' />
      <Route
        component={CompanyDuplicateMergeRecordSelection}
        exact={true}
        path='/companies/:companyId/merge'
      />
      <Route
        component={CompanyDuplicateMergeAttributes}
        exact={true}
        path='/companies/:companyAId/merge/:companyBId'
      />
      <Route
        component={CompanyRecordIndexPage}
        exact={true}
        path='/companies/views/:savedViewId'
      />
      <Route
        component={ContactRecordIndexPage}
        exact={true}
        path='/contacts/views/:savedViewId'
      />
      <Route
        component={SearchRecordIndexPage}
        exact={true}
        path='/searches/views/:savedViewId'
      />
      <Route
        component={ContactForm}
        exact={true}
        path='/contacts/pending/:contactReviewId'
      />
      <Route
        component={PendingContactsPage}
        exact={true}
        path='/contacts/pending'
      />
      <Route
        component={ContactForm}
        exact={true}
        path='/contacts/:contactId/pending/:contactReviewId'
      />
      <Route
        component={CandidatePortalConsentPageEnhanced}
        exact={true}
        path='/contacts/:contactId/consent'
      />
      <Route
        component={ContactDuplicateMergeRecordSelection}
        exact={true}
        path='/contacts/:contactId/merge'
      />
      <Route
        component={ContactDuplicateMergeAttributes}
        exact={true}
        path='/contacts/:contactAId/merge/:contactBId'
      />
      <Route component={ContactPage} exact={true} path='/contacts/:contactId' />
      <Route component={EmailTemplatesPage} path='/email_templates' />
      <Route component={NotificationsPage} path='/notifications' />
      <Route component={ReportsPage} path='/reports' />
      <Route
        component={TalentPoolsDashboard}
        exact={true}
        path='/talent_pools'
      />
      <Route component={SearchesPage} path='/talent_pools/:searchId' />
      <Route component={SearchesPage} path='/searches' />
      <Route component={TasksPage} path='/tasks' />
      <Route component={CompaniesPage} path='/companies' />
      <Route component={PipelineIndexPage} exact={true} path='/pipelines' />
      <Route component={SearchesPage} path='/pipelines/:searchId' />
      <Route
        component={SearchRequestIndexPage}
        exact={true}
        path='/search_requests'
      />
      <Route
        component={SearchRequestPage}
        path='/search_request_form/:searchRequestId?'
      />
      <Route
        component={SearchRequestProfilePage}
        exact={true}
        path='/search_requests/:searchRequestId'
      />
    </Switch>
  );
};

export default AppPages;
