import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import HtmlText from '@thrivetrm/ui/components/HtmlText';
import useToggle from '@thrivetrm/ui/hooks/useToggle';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import Icon from '@thrivetrm/ui/components/Icon';
import Rating from '@thrivetrm/ui/components/Rating';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';
import ExpandableText from '@thrivetrm/ui/components/ExpandableText';
import Notification from './Notification';
import './CandidacyAssessment.scss';

const MAX_SUMMARY_NOTES_HEIGHT = 57;

const CandidacyAssessment = ({
  isLatestId,
  notification,
  showDetails,
  ...props
}) => {
  const summaryNotedRef = useRef();
  const ratingStars = useSelector(state => state.tenant)?.get('rating_stars');
  const [isExpanded, _expand, _shrink, toggleExpandView] = useToggle(false);
  const [summaryNotesHeight, setSummaryNotesHeight] = useState();
  const hasAssessmentSummaryViewFeature = useFeatureCheck(
    'development.assessment_v2_summary_view',
  );

  const notificationData = notification?.toJS();
  const summaryNotes = isLatestId
    ? notificationData.data.resource.data.notes
    : `<a
  href="/searches/${notificationData.data.search_id}/candidates/${notificationData.data.candidacy_id}/candidacy_assessments/${notificationData.data.resource.data.id}"
>
  View latest version of assessment
</a>`;
  const template = notificationData.data?.assessment_template;
  const answers =
    notificationData.data?.candidacy_assessment_answers_attributes;
  const isNotesPresentInAnswers = answers?.find(answer => answer.notes);

  useEffect(() => {
    setSummaryNotesHeight(summaryNotedRef?.current?.clientHeight);
  }, [summaryNotes]);

  const renderContent = () =>
    hasAssessmentSummaryViewFeature ? (
      <>
        <div className='u-paddingBottom-8'>
          {template.criteria.map(({ id, name }) => {
            const criteriaDetails = answers?.find(
              answer => answer.assessment_criterium_id === id,
            );
            return (
              <div className='u-paddingVertical-2' key={id}>
                <div className='u-flex u-flexAlign-c'>
                  <Rating
                    maxRating={ratingStars}
                    value={criteriaDetails?.rating_value}
                  />
                  <p className='u-margin-n u-paddingLeft-4 u-fontSize-small'>
                    {name}
                  </p>
                </div>
                {isExpanded && criteriaDetails?.notes ? (
                  <>
                    <h5 className='u-margin-n u-marginTop-4'>Notes:</h5>
                    <HtmlText
                      className='u-wordBreak u-marginTop-2 u-fontSize-small'
                      htmlString={criteriaDetails?.notes}
                    />
                  </>
                ) : null}
              </div>
            );
          })}
        </div>

        {isLatestId &&
          template.categories.map(({ criteria, id, name }, index) => {
            const criteriaAverage = answers?.find(
              answer =>
                answer.assessment_category_id === id && answer.criteria_average,
            )?.criteria_average;
            return (
              <div
                className={classNames('u-paddingBottom-12', {
                  'u-borderTop u-paddingTop-12': index,
                })}
                key={id}
              >
                <h4 className='u-margin-n u-marginBottom-8'>
                  Category: {name}{' '}
                  {criteria?.length
                    ? `- ${(criteriaAverage || 0).toFixed(2)} Average`
                    : null}
                </h4>

                {criteria.map(criteriaData => {
                  const criteriaAnswer = answers?.find(
                    answer =>
                      answer.assessment_category_id === id &&
                      answer.assessment_criterium_id === criteriaData.id,
                  );
                  return (
                    <div className='u-paddingVertical-2' key={criteriaData.id}>
                      <div className='u-flex u-flexAlign-c'>
                        <Rating
                          maxRating={ratingStars}
                          value={criteriaAnswer?.rating_value}
                        />
                        <p className='u-margin-n u-paddingLeft-4 u-fontSize-small'>
                          {criteriaData.name}
                        </p>
                      </div>
                      {isExpanded && criteriaAnswer?.notes ? (
                        <>
                          <h5 className='u-margin-n u-marginTop-4'>Notes:</h5>
                          <HtmlText
                            className='u-wordBreak u-marginTop-2 u-fontSize-small'
                            htmlString={criteriaAnswer?.notes}
                          />
                        </>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            );
          })}
        {/* Conditionally render Summary Notes */}
        {isLatestId ? (
          <>
            <strong>Summary Notes:</strong>
            <div ref={summaryNotedRef}>
              <HtmlText
                className={classNames(
                  'CandidacyAssessment__htmlText u-wordBreak',
                  {
                    'CandidacyAssessment__htmlText--isShortView': !isExpanded,
                  },
                )}
                htmlString={summaryNotes}
              />
            </div>
            {summaryNotesHeight >= MAX_SUMMARY_NOTES_HEIGHT ||
            isNotesPresentInAnswers ? (
              <div className='u-flex u-flexAlign-c'>
                <Icon
                  color='blue'
                  type={isExpanded ? 'chevronUp' : 'chevronDown'}
                />
                <ButtonLink
                  className='u-marginLeft-2'
                  icon='add'
                  label={isExpanded ? 'View Less' : 'View More'}
                  onClick={toggleExpandView}
                />
              </div>
            ) : null}
          </>
        ) : (
          <div className='CandidacyAssessment__link'>
            <ExpandableText
              characterLimit={150}
              content={summaryNotes}
              isSanitizedHtml={true}
            />
          </div>
        )}
      </>
    ) : (
      <>
        <strong className='u-fontSize-small'>Summary Notes:</strong>
        <ExpandableText
          characterLimit={150}
          content={summaryNotes}
          isSanitizedHtml={true}
        />
      </>
    );

  return (
    <Notification
      {...props}
      notification={notification}
      showDetails={showDetails}
    >
      {summaryNotes && showDetails ? renderContent() : null}
    </Notification>
  );
};

CandidacyAssessment.propTypes = {
  /**
   * The notification object
   */
  isLatestId: PropTypes.bool,
  notification: ImmutablePropTypes.mapContains({
    data: ImmutablePropTypes.mapContains({
      resource: ImmutablePropTypes.mapContains({
        data: ImmutablePropTypes.mapContains({
          truncated_comment: PropTypes.string,
        }),
      }),
      type: PropTypes.oneOf([
        'client_candidacy_assessment',
        'researcher_candidacy_assessment',
        'recruiter_candidacy_assessment',
      ]),
    }),
  }).isRequired,
  showDetails: PropTypes.bool,
};

export default CandidacyAssessment;
