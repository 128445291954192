import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, setDisplayName, setPropTypes } from 'recompose';
import ModalButton from 'modules/core/componentsLegacy/ModalButton';
import TooltipTrigger from 'modules/core/componentsLegacy/TooltipTrigger';
import mapContactIdToContact from '../mapContactIdToContact';
import CandidatePortalInviteModal from './CandidatePortalInviteModal';

const INVITE_STATUS = {
  NONE: 'Invite to Candidate Portal',
  SENT: 'Resend Invitation',
  ACCEPTED: 'Invitation Accepted',
};

const CandidatePortalInviteModalButton = ({ contact, user, ...props }) => {
  const getInviteStatus = () => {
    if (user && user.get('invitation_accepted_at')) {
      return INVITE_STATUS.ACCEPTED;
    }

    if (user && user.get('invitation_created_at')) {
      return INVITE_STATUS.SENT;
    }

    return INVITE_STATUS.NONE;
  };

  const isGdprStatusRevoked = contact?.get('gdpr_status') === 'Consent Revoked';

  const getModalButton = () => {
    if (isGdprStatusRevoked) {
      return (
        <TooltipTrigger
          placement='left'
          tooltip='This contact has revoked consent, so a Candidate Portal invitation cannot be sent'
          trigger={isGdprStatusRevoked ? ['hover', 'focus'] : []}
        >
          <span className='CandidatePortalInviteModalButton'>
            <button
              className='CandidatePortalInviteModalButton--disabled btn btn-default'
              disabled={true}
              type='button'
            >
              {getInviteStatus(user)}
            </button>
          </span>
        </TooltipTrigger>
      );
    }

    return (
      <ModalButton
        {...props}
        className='CandidatePortalInviteModalButton btn btn-default'
        contact={contact}
        modal={CandidatePortalInviteModal}
      >
        {getInviteStatus(user)}
      </ModalButton>
    );
  };

  return getModalButton();
};

CandidatePortalInviteModalButton.propTypes = {
  contact: PropTypes.shape({
    user_id: PropTypes.number,
  }),
  user: PropTypes.shape({
    invitation_accepted_at: PropTypes.number,
    invitation_created_at: PropTypes.string,
  }),
};

export default compose(
  setDisplayName('CandidatePortalInviteModalButton'),
  setPropTypes({
    /**
     * The ID of the contact whose invite is being sent.
     */
    contactId: PropTypes.number.isRequired,
  }),
  mapContactIdToContact,
  withProps(({ contact }) => ({
    user: contact.get('user'),
  })),
)(CandidatePortalInviteModalButton);
