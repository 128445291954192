import React from 'react';
import routes from 'modules/routing/routes';
import PropTypes from 'prop-types';
import DuplicateMergeAttributes from './DuplicateMergeAttributes';

const AUTO_MERGE_ATTRIBUTES = [
  'Aliases',
  'Assessments',
  'Comments',
  'Connections',
  'Documents',
  'Education',
  'Event Guest attendance',
  'Experience',
  'Networks',
  'Notes',
  'Off Limits Reasons',
  'Offers',
  'Outreach',
  'Ratings',
  'References',
  'Searches',
  'Tags',
];

const contactAIcon = (
  <svg
    className='DuplicateMergeLoading__icon DuplicateMergeLoading__icon--left'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M114.036 46.147a104.895 104.895 0 0075.549 32.103 104.871 104.871 0 0041.532-8.562' />
    <path d='M231.719 78.25v63.219c0 34.915-28.304 63.219-63.219 63.219-34.915 0-63.219-28.304-63.219-63.22V78.25c0-34.915 28.304-63.219 63.219-63.219 34.915 0 63.219 28.304 63.219 63.219zM284.521 285.969c-6.782-43.41-37.204-79.415-78.872-93.347M131.351 192.646c-41.668 13.933-72.09 49.937-78.872 93.347M198.23 207.078a4.375 4.375 0 00-5.955-1.866L174.11 214.9a8.75 8.75 0 010 14.201l18.165 9.686a4.375 4.375 0 005.956-1.866 32.874 32.874 0 000-29.844zM139.77 207.078a4.375 4.375 0 015.955-1.866l18.165 9.689a8.75 8.75 0 000 14.201l-18.165 9.686a4.375 4.375 0 01-5.956-1.866 32.874 32.874 0 010-29.844h0z' />
    <circle cx='169' cy='222' r='8.75' />
    <path d='M177.75 222h6.563M153.688 222h6.562M186.8 87.23a5.928 5.928 0 01-5.934 5.924h-23.732a5.928 5.928 0 01-5.934-5.923 5.928 5.928 0 015.934-5.923h23.732a5.928 5.928 0 015.934 5.923z' />
    <path d='M186.8 87.23h5.933c11.469 0 20.766 9.282 20.766 20.732 0 11.449-9.297 20.73-20.766 20.73-11.469 0-20.766-9.281-20.766-20.73h-5.934a20.73 20.73 0 01-12.819 19.152 20.792 20.792 0 01-22.63-4.494 20.705 20.705 0 01-4.502-22.592 20.767 20.767 0 0119.185-12.797h5.933' />
    <path d='M105 96.392a77.592 77.592 0 0027.836 53.086c11.026 6.012 19.07-16.702 35.29-16.702M168.127 132.776c16.208 0 24.264 22.738 35.278 16.702a77.604 77.604 0 0027.848-53.086' />
  </svg>
);

const contactBIcon = (
  <svg
    className='DuplicateMergeLoading__icon DuplicateMergeLoading__icon--right'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M231.877 87.145v54.108c0 34.864-28.262 63.127-63.126 63.127s-63.127-28.263-63.127-63.127V87.145h126.253zM284.603 285.542c-6.78-43.337-37.157-79.278-78.758-93.187M131.656 192.355c-41.6 13.909-71.977 49.85-78.757 93.187' />
    <path d='M249.913 33.036c0-9.96-8.075-18.036-18.036-18.036H105.624c-9.96 0-18.036 8.075-18.036 18.036v45.09a9.018 9.018 0 009.018 9.019h144.29a9.018 9.018 0 009.017-9.018v-45.09zM159.733 51.072h18.036M132.678 285.542l97.035-82.112M186.787 285.542l70.052-59.279M105.624 96.163a77.592 77.592 0 0027.836 53.086c11.026 6.012 19.07-16.701 35.29-16.701M168.75 132.548c16.21 0 24.265 22.737 35.28 16.701a77.604 77.604 0 0027.847-53.086' />
    <path d='M87.792 80.026l-.408-.348c-11.123-9.163-36.493 3.234-56.658 27.655a92.2 92.2 0 00-16.377 60.974 92.26 92.26 0 0056.658-27.655c16.918-20.585 24.806-43.07 20.345-55.203a9.066 9.066 0 01-3.56-5.423h0z' />
  </svg>
);

const ContactDuplicateMergeAttributes = ({ match }) => {
  const { contactAId, contactBId } = match.params;
  const contactAttributesEndpoint = routes.edit_api_v1_contact_merge({
    contact_id: contactAId,
    id: contactBId,
  });
  const contactMergeEndpoint = routes.api_v1_contact_merge({
    contact_id: contactAId,
    id: contactBId,
  });

  return (
    <DuplicateMergeAttributes
      attributesEndpoint={contactAttributesEndpoint}
      autoMergeAttributes={AUTO_MERGE_ATTRIBUTES}
      avatarShape='circle'
      mergeEndpoint={contactMergeEndpoint}
      recordAIcon={contactAIcon}
      recordAProfileUrl={routes.contact({ id: contactAId })}
      recordBIcon={contactBIcon}
      recordLabel='Contact'
    />
  );
};

ContactDuplicateMergeAttributes.propTypes = {
  // provided by react-router
  match: PropTypes.shape({
    params: PropTypes.shape({
      contactAId: PropTypes.string.isRequired,
      contactBId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ContactDuplicateMergeAttributes;
