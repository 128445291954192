import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import url from 'url';
import TextInput from '@thrivetrm/ui/components/TextInput';
import SelectMenu from '@thrivetrm/ui/components/SelectMenu';
import ButtonPrimary from '@thrivetrm/ui/components/ButtonPrimary';
import Grid from '@thrivetrm/ui/components/Grid';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';
import Icon from '@thrivetrm/ui/components/Icon';
import { stringifyQueryObject } from '@thrivetrm/ui/utilities/urlUtils';
import MultiValueInput from 'modules/core/components/MultiValueInput';
import getTenantOptionsIds from 'modules/tenant/selectors/getTenantOptionsIds';
import getEntityProperty from 'modules/entities/selectors/getEntityProperty';
import countrySchema from 'modules/tenant/schemas/countries';
import statechema from 'modules/tenant/schemas/states';
import routes from 'modules/routing/routes';
import useFeatureCheck from 'modules/auth/hooks/useFeatureCheck';

const employmentOptions = [
  { label: 'Current', value: '2' },
  { label: 'Past', value: '1' },
  { label: 'All', value: '0' },
];

const CompanyContactsTableFilters = ({
  companyId,
  initialFilters,
  onChange,
}) => {
  const [filters, setFilters] = useState(initialFilters);
  const hasContactIndexV5Feature = useFeatureCheck(
    'development.contacts_index_page_v5',
  );
  const defaultSavedViewId = useSelector(state =>
    state.user.get('contacts_default_saved_view_id'),
  );
  const countryIds = useSelector(state =>
    getTenantOptionsIds(state, countrySchema.key),
  );
  const stateIds = useSelector(state =>
    getTenantOptionsIds(state, statechema.key),
  );

  const countryOptions = useSelector(state =>
    countryIds
      ?.map(id => ({
        value: id,
        label: getEntityProperty(state, countrySchema.key, id, 'name'),
      }))
      .toArray(),
  );

  const stateOptions = useSelector(state =>
    stateIds
      ?.map(id => ({
        value: id,
        label: getEntityProperty(state, statechema.key, id, 'name'),
      }))
      .toArray(),
  );

  const handleChange = (value, filterKey) => {
    setFilters({ ...filters, [filterKey]: value });
  };

  // translates the filter_positions param from the selector on the company contacts filters
  // to params for the checkboxes on the advanced search modal on the Contacts index
  const status = filters.filter_positions;
  // filter positions: [0 - All], [1 - Past], [2 - Current]
  // filter_past_company: past[status] will return 1 (true) on [0 - All] and [1 - Past]
  // filter_current_company: current[status] will return 1 (true) on [0 - All] and [2 - Current]
  const past = { 0: '1', 1: '1', 2: '0' };
  const current = { 0: '1', 1: '0', 2: '1' };
  const position = filters.position?.length > 0;
  const companyFilters = {
    filter_past_company: past[status],
    filter_current_company: current[status],
    // if a position is included, then the checkbox params will be included
    ...(position && {
      filter_past_position: past[status],
      filter_current_position: current[status],
    }),
  };

  const handleBulkActions = () => {
    const {
      country_code: countryCode,
      position: primaryPosition,
      us_state: usState,
    } = filters;
    const filterSetIds = ['Company ID'];
    if (countryCode) {
      filterSetIds.push('Countries');
    }
    if (usState) {
      filterSetIds.push('States/Provinces');
    }
    const companyAndPositionFilters = hasContactIndexV5Feature
      ? {
          selected_filter_set_ids: filterSetIds,
          country_code: countryCode,
          us_state: usState,
          filter_current_company_id: current[status],
          filter_past_company_id: past[status],
          company_id: companyId,
          exclude_countries: 0,
          // if a position is included, then the checkbox params will be included
          ...(position && {
            filter_current_position_boolean: current[status],
            filter_past_position_boolean: past[status],
            position_boolean: { values: [primaryPosition] },
          }),
        }
      : null;

    const contactFilters = companyAndPositionFilters || {
      company: companyId,
      ...filters,
      ...companyFilters,
    };

    window.location.href = url.format({
      pathname: routes.saved_view_contacts({ id: defaultSavedViewId }),
      search: stringifyQueryObject({ filters: contactFilters }),
    });
  };

  const handleKeyPress = e => (e.key === 'Enter' ? onChange(filters) : null);

  return (
    <Grid
      className='u-paddingHorizontal-8 u-marginBottom-8'
      data-testid='company-contacts-table-filters'
    >
      <Grid.Column className='u-paddingHorizontal-4' size={3}>
        <TextInput
          data-testid='job-title-filter'
          inputWidth='full'
          label='Title'
          onChange={newValue => handleChange(newValue, 'position')}
          onKeyPress={handleKeyPress}
          value={filters.position}
        />
      </Grid.Column>
      <Grid.Column className='u-paddingHorizontal-4' size={3}>
        <MultiValueInput
          canUseCustomOptions={true}
          data-testid='states-filter'
          label='States/Provinces'
          onChange={newValue => handleChange(newValue, 'us_state')}
          onKeyPress={handleKeyPress}
          options={stateOptions}
          value={filters.us_state}
        />
      </Grid.Column>
      <Grid.Column className='u-paddingHorizontal-4' size={3}>
        <MultiValueInput
          canUseCustomOptions={true}
          data-testid='countries-filter'
          label='Countries'
          onChange={newValue => handleChange(newValue, 'country_code')}
          onKeyPress={handleKeyPress}
          options={countryOptions}
          value={filters.country_code}
        />
      </Grid.Column>
      <Grid.Column className='u-paddingHorizontal-4 u-paddingBottom-4' size={2}>
        <div className='u-paddingBottom-4 u-textColor-gray60'>
          <strong>Employment</strong>
        </div>
        <SelectMenu
          data-testid='employment-filter'
          inputWidth='full'
          onChange={newValue => handleChange(newValue, 'filter_positions')}
          value={filters.filter_positions}
        >
          {employmentOptions.map(option => (
            <SelectMenu.Item key={option.value} value={option.value}>
              {option.label}
            </SelectMenu.Item>
          ))}
        </SelectMenu>
      </Grid.Column>
      <Grid.Column className='u-paddingTop-24 u-paddingHorizontal-4' size={1}>
        <ButtonPrimary
          className='u-paddingHorizontal-12'
          data-testid='filter-button'
          icon='filter'
          label='Filter'
          onClick={() => onChange({ ...filters, ...companyFilters })}
        />
      </Grid.Column>
      <Grid.Column className='text-right u-paddingRight-4' size={12}>
        <ButtonLink
          className='u-paddingTop-8'
          data-testid='bulk-actions-button'
          label={
            <div className='u-inlineFlex'>
              <Icon color='blue' type='export' />
              Bulk actions, advanced filters & export
            </div>
          }
          onClick={handleBulkActions}
        />
      </Grid.Column>
    </Grid>
  );
};

CompanyContactsTableFilters.defaultProps = {
  initialFilters: null,
};

CompanyContactsTableFilters.propTypes = {
  companyId: PropTypes.number.isRequired,
  initialFilters: PropTypes.shape({
    filter_positions: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

export default CompanyContactsTableFilters;
