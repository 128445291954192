import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { compose, setStatic } from 'recompose';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import classnames from 'classnames';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import AddressField from 'modules/addresses/components/AddressField';
import CheckboxField from 'modules/forms/components/CheckboxField';
import FieldState from 'modules/forms/FieldState';
import InputGroupField from 'modules/forms/components/InputGroupField';
import InputField from 'modules/forms/components/InputField';
import * as validators from 'modules/forms/validators';
import isCandidateUser from 'modules/auth/selectors/isCandidateUser';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import ContactExternalIdInputField from 'modules/contacts/components/ContactExternalIdInputField';
import ContactSourceSelectField from 'modules/tenant/components/ContactSourceSelectField';
import contactSourceHasDetailsSelector from 'modules/tenant/selectors/contactSourceHasDetails';
import uniqueId from '@thrivetrm/ui/utilities/uniqueId';
import withContactDuplicates from '../withContactDuplicates';
import labels from '../../../labels/index.json';
import AboveBoardIcon from './AboveBoardIcon';
/**
 * Displays a form for editing a contact's social fields
 */
class ContactSocialField extends PureComponent {
  static createFieldState(name = 'social', contact) {
    return FieldState.createNested(
      name,
      [
        CheckboxField.createFieldState(
          'is_work_email_preferred',
          contact.get('work_email_preferred') === true,
        ),
        CheckboxField.createFieldState(
          'is_personal_email_preferred',
          contact.get('work_email_preferred') === false,
        ),
        InputGroupField.createFieldState(
          'email',
          contact.get('email') || '',
          validators.emailAddress,
        ),
        InputGroupField.createFieldState(
          'work_email',
          contact.get('work_email') || '',
          validators.emailAddress,
        ),
        InputGroupField.createFieldState('phone', contact.get('phone') || ''),
        InputGroupField.createFieldState(
          'mobile_phone',
          contact.get('mobile_phone') || '',
        ),
        InputGroupField.createFieldState(
          'work_phone',
          contact.get('work_phone') || '',
        ),
        InputGroupField.createFieldState(
          'linkedin_url',
          contact.get('linkedin_url') || '',
        ),
        InputGroupField.createFieldState(
          'twitter_handle',
          contact.get('twitter_handle') || '',
        ),
        InputGroupField.createFieldState(
          'skype_handle',
          contact.get('skype_handle') || '',
        ),
        InputGroupField.createFieldState(
          'github_url',
          contact.get('github_url') || '',
        ),
        ContactSourceSelectField.createFieldState(
          'contact_source_id',
          contact.get('contact_source') || '',
        ),
        InputGroupField.createFieldState(
          'referral_source',
          contact.get('referral_source') || '',
        ),
        InputGroupField.createFieldState(
          'other_url',
          contact.get('other_url') || '',
        ),
        InputGroupField.createFieldState(
          'aboveboard_url',
          contact.get('aboveboard_url') || '',
        ),
        ContactExternalIdInputField.createFieldState(
          'external_id',
          contact.get('external_id') || '',
        ),
        AddressField.createFieldState('address', contact),
      ],
      null,
      fieldValue => {
        let workEmailPreferred = null;
        if (fieldValue.is_work_email_preferred) {
          workEmailPreferred = true;
        } else if (fieldValue.is_personal_email_preferred) {
          workEmailPreferred = false;
        }
        const result = {
          work_email_preferred: workEmailPreferred,
          ...fieldValue,
          ...fieldValue.address,
        };
        delete result.address;
        delete result.is_work_email_preferred;
        delete result.is_personal_email_preferred;
        return result;
      },
    );
  }

  constructor(props) {
    super(props);

    this.componentId = uniqueId();
  }

  handleFieldChange = childFieldState => {
    const { fieldState, onChange } = this.props;
    const fieldName = childFieldState.getName();
    let nextFieldState = fieldState.setNestedField(childFieldState);

    const workEmailPreferred = fieldState.getNestedField(
      'is_work_email_preferred',
    );
    const personalEmailPreferred = fieldState.getNestedField(
      'is_personal_email_preferred',
    );

    if (fieldName === 'is_work_email_preferred') {
      if (workEmailPreferred.getValue() === false) {
        nextFieldState = nextFieldState
          .setNestedField(workEmailPreferred.setValue(true))
          .setNestedField(personalEmailPreferred.setValue(false));
      }
    }

    if (fieldName === 'is_personal_email_preferred') {
      if (personalEmailPreferred.getValue() === false) {
        nextFieldState = nextFieldState
          .setNestedField(workEmailPreferred.setValue(false))
          .setNestedField(personalEmailPreferred.setValue(true));
      }
    }

    onChange(nextFieldState, childFieldState.getName());
  };

  render() {
    const {
      // Prevent these props from being passed through to the input
      // eslint-disable-next-line no-unused-vars
      contactActions,
      contactSourceHasDetails,
      duplicates,
      fieldState,
      hasAboveBoard,
      hasExternalId,
      isCandidate,
      // eslint-disable-next-line no-unused-vars
      onChange,
      // eslint-disable-next-line no-unused-vars
      queryDuplicates,
      userType,
      ...otherProps
    } = this.props;

    return (
      <div className='contact-social-field'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='formgroup-with-label-addon'>
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-for-email-${this.componentId}`}>
                    <div>
                      {labels.preferredEmailTooltip[userType || 'noncandidate']}
                    </div>
                  </Tooltip>
                }
                placement='top'
                trigger={['hover']}
              >
                <div className='formgroup-label-addon'>
                  <CheckboxField
                    checked={fieldState
                      .getNestedField('is_personal_email_preferred')
                      .getValue()}
                    fieldState={fieldState.getNestedField(
                      'is_personal_email_preferred',
                    )}
                    onChange={this.handleFieldChange}
                    text='Preferred'
                  />
                </div>
              </OverlayTrigger>
              <InputGroupField
                {...otherProps}
                className={classnames({ 'has-error': duplicates.email.size })}
                fieldState={fieldState.getNestedField('email')}
                label='Personal Email'
                onChange={this.handleFieldChange}
                prependComponent={<i className='fa fa-envelope' />}
                type='email'
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='formgroup-with-label-addon'>
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-for-work-email-${this.componentId}`}>
                    <div>
                      {labels.preferredEmailTooltip[userType || 'noncandidate']}
                    </div>
                  </Tooltip>
                }
                placement='top'
                trigger={['hover']}
              >
                <div className='formgroup-label-addon'>
                  <CheckboxField
                    checked={fieldState
                      .getNestedField('is_work_email_preferred')
                      .getValue()}
                    fieldState={fieldState.getNestedField(
                      'is_work_email_preferred',
                    )}
                    onChange={this.handleFieldChange}
                    text='Preferred'
                  />
                </div>
              </OverlayTrigger>
              <InputGroupField
                {...otherProps}
                className={classnames({
                  'has-error': duplicates.work_email.size,
                })}
                fieldState={fieldState.getNestedField('work_email')}
                label='Work Email'
                onChange={this.handleFieldChange}
                prependComponent={<i className='fa fa-envelope' />}
                type='email'
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <InputGroupField
              {...otherProps}
              fieldState={fieldState.getNestedField('phone')}
              label='Phone'
              onChange={this.handleFieldChange}
              prependComponent={<i className='fa fa-phone' />}
              type='tel'
            />
          </div>
          <div className='col-md-6'>
            <InputGroupField
              {...otherProps}
              fieldState={fieldState.getNestedField('work_phone')}
              label='Work Phone'
              onChange={this.handleFieldChange}
              prependComponent={<i className='fa fa-phone' />}
              type='tel'
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6'>
            <InputGroupField
              {...otherProps}
              fieldState={fieldState.getNestedField('mobile_phone')}
              label='Mobile Phone'
              onChange={this.handleFieldChange}
              prependComponent={<i className='fa fa-mobile' />}
              type='tel'
            />
          </div>
          <div className='col-md-6'>
            <InputGroupField
              {...otherProps}
              fieldState={fieldState.getNestedField('linkedin_url')}
              label='LinkedIn Public Profile URL'
              onChange={this.handleFieldChange}
              prependComponent={<i className='fa fa-linkedin' />}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6'>
            <InputGroupField
              {...otherProps}
              fieldState={fieldState.getNestedField('twitter_handle')}
              label='Twitter Handle'
              onChange={this.handleFieldChange}
              prependComponent={<i className='fa fa-twitter' />}
            />
          </div>
          <div className='col-md-6'>
            <InputGroupField
              {...otherProps}
              fieldState={fieldState.getNestedField('skype_handle')}
              label='Skype Handle'
              onChange={this.handleFieldChange}
              prependComponent={<i className='fa fa-skype' />}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6'>
            <InputGroupField
              {...otherProps}
              fieldState={fieldState.getNestedField('github_url')}
              label='GitHub Profile URL'
              onChange={this.handleFieldChange}
              prependComponent={<i className='fa fa-github' />}
            />
          </div>
          <div className='col-md-6'>
            <InputGroupField
              {...otherProps}
              fieldState={fieldState.getNestedField('other_url')}
              label='Other Link/URL'
              onChange={this.handleFieldChange}
              prependComponent={<i className='fa fa-link' />}
            />
          </div>
          {hasAboveBoard && (
            <div className='col-md-6'>
              <InputGroupField
                {...otherProps}
                fieldState={fieldState.getNestedField('aboveboard_url')}
                label='AboveBoard URL'
                onChange={this.handleFieldChange}
                prependComponent={
                  <AboveBoardIcon backgroundColor='#f6f8fc' color='black' />
                }
              />
            </div>
          )}
        </div>
        {!isCandidate ? (
          <div className='row'>
            <ContactSourceSelectField
              {...otherProps}
              className='col-md-6 ContactSocialField__contact_source'
              fieldState={fieldState.getNestedField('contact_source_id')}
              isClearable={true}
              label='Source'
              name='contact_source_id'
              onChange={this.handleFieldChange}
              prependComponent={<i className='fa fa-crosshairs' />}
            />
            {contactSourceHasDetails ? (
              <InputField
                {...otherProps}
                className='col-md-6 ContactSocialField__referral_source'
                fieldState={fieldState.getNestedField('referral_source')}
                label='Referral Source'
                name='referral_source'
                onChange={this.handleFieldChange}
              />
            ) : null}
          </div>
        ) : null}
        {hasExternalId && !isCandidate && (
          <div className='row'>
            <div className='col-md-6'>
              <ContactExternalIdInputField
                fieldState={fieldState.getNestedField('external_id')}
                onChange={this.handleFieldChange}
                showTooltip={true}
              />
            </div>
          </div>
        )}
        <div className='row'>
          <div className='col-md-12'>
            <AddressField
              fieldState={fieldState.getNestedField('address')}
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

ContactSocialField.propTypes = {
  /**
   * Called when the field is changed with the updated FieldState object.
   */
  contactActions: PropTypes.shape({
    createDuplicateContactsQuery: PropTypes.func.isRequired,
    destroyDuplicateContactsQuery: PropTypes.func.isRequired,
    queryDuplicateContacts: PropTypes.func.isRequired,
    updateContact: PropTypes.func.isRequired,
  }),

  contacts: ImmutablePropTypes.mapContains({
    duplicatesById: ImmutablePropTypes.map.isRequired,
  }),
  contactSourceHasDetails: PropTypes.bool.isRequired,

  duplicates: PropTypes.shape({
    email: PropTypes.instanceOf(List),
    name: PropTypes.instanceOf(List),
    work_email: PropTypes.instanceOf(List),
  }),

  /**
   * The FieldState that manages the value of the control.
   */
  fieldState: PropTypes.instanceOf(FieldState).isRequired,

  /**
   * Permission check that controls whether or not we render the AboveBoard URL field
   */
  hasAboveBoard: PropTypes.bool,

  /**
   * Permission check that controls whether or not we render the External ID field
   */
  hasExternalId: PropTypes.bool,

  isCandidate: PropTypes.bool,

  /**
   * The FieldState that manages the value of the control.
   */
  onChange: PropTypes.func,

  /**
   * Whether to query duplicates on page load. Used in withContactDuplicates
   * only
   */
  queryDuplicates: PropTypes.bool,

  userType: PropTypes.string,
};

export default compose(
  setStatic('createFieldState', ContactSocialField.createFieldState),
  withContactDuplicates(),
  withFeatureCheck('field.contact.external_id', 'hasExternalId'),
  withFeatureCheck('feature.aboveboard', 'hasAboveBoard'),
  connect(
    (state, { fieldState }) => ({
      isCandidate: isCandidateUser(state),
      contactSourceHasDetails: contactSourceHasDetailsSelector(
        state,
        fieldState.getNestedFieldValue('contact_source_id'),
      ),
    }),
    {},
  ),
)(ContactSocialField);
